/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_PORTFOLIO_QUERY = gql`
  query Portfolio($userId: String) {
    user(id: $userId) {
      properties {
        amount
        property {
          id
          token {
            name
            symbol
            totalSupply
          }
          tokenValuation
          propertyValuation
          cp {
            id
          }
        }
      }
      pendingRents {
        property {
          id
          cp {
            id
          }
        }
        pendingRent
      }
    }
  }
`;
