/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import API from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import i18n from 'i18n';
import { RESET_STATE } from './sharedActions';

const initialState = {
  countries: [],
  isRequesting: false,
  errorMessage: '',
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    countriesUpdateRequest(state) {
      state.isRequesting = true;
    },
    countriesUpdateSuccess(state, action) {
      state.isRequesting = false;
      state.countries = action.payload;
    },
    countriesUpdateFailure(state, action) {
      const { reason } = action.payload;
      state.isRequesting = false;
      state.errorMessage = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function countriesFetchRequest() {
  return async (dispatch, getState) => {
    const state = getState();
    const { countries } = state.countries;
    if (!countries.countries) {
      dispatch(countriesUpdateRequest());
      return API.get('/country')
        .then((response) => {
          const { data } = response;
          dispatch(countriesUpdateSuccess(data));
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(countriesUpdateFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
    return null;
  };
}

export const { countriesUpdateRequest, countriesUpdateSuccess, countriesUpdateFailure } =
  countriesSlice.actions;
export default countriesSlice.reducer;
