/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API, { STATUS_SUCCESS } from 'utils/api';
import { createAndSignWalletObject } from 'utils/user';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { hideDialogs } from 'redux/dialogs';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isInitialState: true,
  isFetching: false,
  isUpdatingRentPayout: false,
  isFetchingRentPayout: false,
  isUpdatingWalletAddress: false,
  fetchErrorMessage: null,
  updateRentPayoutErrorMessage: null,
  fetchRentPayoutErrorMessage: null,
  updateWalletAddressErrorMessage: null,
  updateWalletAddressSuccess: false,
  address: '',
  rentPayout: {
    code: '',
    name: '',
  },
  walletStatus: '',
  walletType: '',
  wallets: [],
  isUpdatingWalletName: false,
  updateWalletNameErrorMessage: null,
};

const userWalletSlice = createSlice({
  name: 'userWallet',
  initialState,
  reducers: {
    rentPayoutUpdateRequest(state) {
      state.isUpdatingRentPayout = true;
    },
    rentPayoutUpdateSuccess(state) {
      state.isUpdatingRentPayout = false;
      state.updateRentPayoutErrorMessage = null;
    },
    rentPayoutUpdateFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingRentPayout = false;
      state.updateRentPayoutErrorMessage = reason;
    },
    rentPayoutFetchRequest(state) {
      state.isFetchingRentPayout = true;
    },
    rentPayoutFetchSuccess(state) {
      state.isFetchingRentPayout = false;
      state.fetchRentPayoutErrorMessage = null;
    },
    rentPayoutFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isFetchingRentPayout = false;
      state.fetchRentPayoutErrorMessage = reason;
    },
    walletUpdateRequest(state) {
      state.isUpdatingWalletAddress = true;
    },
    walletUpdateSuccess(state) {
      state.isUpdatingWalletAddress = false;
    },
    walletUpdateFailure(state, action) {
      const { reason } = action.payload;
      state.updateWalletAddressErrorMessage = reason;
      state.isUpdatingWalletAddress = false;
    },
    walletFetchRequest(state) {
      state.isFetching = true;
    },
    walletFetchSuccess(state, action) {
      // Sort wallets by oldest -> newest
      state.wallets = action.payload.sort((a, b) => (a.created > b.created ? 1 : -1));
      state.isFetching = false;
      state.fetchErrorMessage = null;
      state.isInitialState = false;
    },
    walletFetchFailure(state, action) {
      const { reason } = action.payload;
      state.wallets = [];
      state.isFetching = false;
      state.fetchErrorMessage = reason;
    },
    walletChanged(state, action) {
      const { address } = action.payload;
      state.address = address;
    },
    walletNameChangeRequest(state) {
      state.isUpdatingWalletName = true;
    },
    walletNameChangeSuccess(state) {
      state.isUpdatingWalletName = false;
      state.updateWalletNameErrorMessage = null;
    },
    walletNameChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingWalletName = false;
      state.updateWalletNameErrorMessage = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userWalletFetchRequest() {
  return async (dispatch, getState) => {
    const state = getState();
    const { userWallet } = state;
    if (userWallet.isFetching === false) {
      dispatch(walletFetchRequest());
      return API.get('/account/wallet', {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      })
        .then((response) => {
          const { data } = response;
          dispatch(walletFetchSuccess(data));
        })
        .catch(() => {
          const networkError = i18n.t('network_error');
          dispatch(walletFetchFailure({ reason: networkError }));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        });
    }
    return null;
  };
}

export function userRentPayoutUpdateRequest(rentPayoutData) {
  return async (dispatch, getState) => {
    dispatch(rentPayoutUpdateRequest());
    return API.put('/account/rent-payout', rentPayoutData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(rentPayoutUpdateSuccess());
          dispatch(createNotification('Rent payout option successfully changed'));
        } else {
          dispatch(rentPayoutUpdateFailure({ reason: data.reason }));
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(rentPayoutUpdateFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userRentPayoutFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(rentPayoutFetchRequest());
    return API.get('/account/rent-payout', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === STATUS_SUCCESS) {
          dispatch(rentPayoutFetchSuccess());
        } else {
          dispatch(rentPayoutFetchFailure(status));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(rentPayoutFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userAddWalletRequest(walletType, userAccount) {
  return async (dispatch, getState) => {
    dispatch(walletUpdateRequest());
    const walletData = await createAndSignWalletObject(walletType, userAccount);
    return API.post('/account/wallet', walletData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(walletUpdateSuccess());
          dispatch(hideDialogs());
          dispatch(createNotification('Add wallet successfully.'));
          dispatch(userWalletFetchRequest());
        } else {
          dispatch(walletUpdateFailure(data));
          dispatch(hideDialogs());
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(walletUpdateFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userChangeWalletNameRequest(walletData) {
  return async (dispatch, getState) => {
    dispatch(walletNameChangeRequest());
    return API.put('/account/wallet', walletData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(walletNameChangeSuccess());
          dispatch(createNotificationByType(NOTIFICATION_TYPES.WALLET_NAME_CHANGED));
          dispatch(userWalletFetchRequest(true));
        } else {
          dispatch(walletNameChangeFailure(data));
          dispatch(createNotification(data.reason, 'error'));
        }
        dispatch(hideDialogs());
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(walletNameChangeFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  rentPayoutUpdateRequest,
  rentPayoutUpdateSuccess,
  rentPayoutUpdateFailure,
  rentPayoutFetchRequest,
  rentPayoutFetchSuccess,
  rentPayoutFetchFailure,
  walletFetchRequest,
  walletFetchSuccess,
  walletFetchFailure,
  walletUpdateRequest,
  walletUpdateSuccess,
  walletUpdateFailure,
  walletNameChangeRequest,
  walletNameChangeSuccess,
  walletNameChangeFailure,
  walletChanged,
} = userWalletSlice.actions;
export default userWalletSlice.reducer;
