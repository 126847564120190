import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { initI18n } from '../i18n';

import rootReducer from './rootReducer';

/* const globalFilter = createWhitelistFilter('global', ['company']); */
const authenticationFilter = createWhitelistFilter('authentication', [
  'token',
  'groups',
  'expireAt',
  'site',
]);
const languageFilter = createWhitelistFilter('language', ['selectedLanguage']);
const currencyFilter = createWhitelistFilter('currency', ['selectedCurrency']);
const walletFilter = createWhitelistFilter('wallet', ['account', 'type']);
// TODO: Issue is that the Zero0xWrapper is not initialized when we load web3Connect from the local store

const persistConfig = {
  key: 'blocksquare-dashboard',
  storage,
  whitelist: 'authentication, language, currency, wallet',
  transforms: [authenticationFilter, languageFilter, currencyFilter, walletFilter],
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  // This adds support for Redux DevTools in development mode
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store, {}, () => {
  initI18n(store);
});

export { store, persistor };
