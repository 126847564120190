import { ContractWrappers } from '@0x/contract-wrappers';
import { Web3Wrapper } from '@0x/web3-wrapper';

export default class ZeroExWrapper {
  static instance = null;

  contractsWrapper = null;

  web3Wrapper = null;

  provider = null;

  static getInstance() {
    if (ZeroExWrapper.instance == null) {
      ZeroExWrapper.instance = new ZeroExWrapper();
    }

    return this.instance;
  }

  setWrappers(provider) {
    const chainId = +process.env.REACT_APP_CHAIN_ID;
    this.provider = provider;
    this.contractsWrapper = new ContractWrappers(provider, { chainId });
    this.web3Wrapper = new Web3Wrapper(provider);
  }

  getWeb3Wrapper() {
    return this.web3Wrapper;
  }

  getContractWrapper() {
    return this.contractsWrapper;
  }

  getProvider() {
    return this.provider;
  }
}
