import { ERC20TokenContract, OrderStatus } from '@0x/contract-wrappers';
import { assetDataUtils, generatePseudoRandomSalt, signatureUtils } from '@0x/order-utils';
import { Web3Wrapper } from '@0x/web3-wrapper';
import { BigNumber } from 'bignumber.js';
/* import { store } from 'redux/index';
import { DIALOG_TYPES, showDialog } from 'redux/dialogs'; */
import ZeroExWrapper from './ZeroExWrapper';
import {
  BIGINT_ZERO,
  NULL_ADDRESS,
  NULL_BYTES,
  UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
} from './constants';

export const DECIMALS = 18;
export const TX_DEFAULTS = { gas: 800000, gasPrice: 1000000000 };

const chainId = +process.env.REACT_APP_CHAIN_ID;

export const calculateProtocolFee = (orders, gasPrice = TX_DEFAULTS.gasPrice) => {
  return new BigNumber(150000).times(gasPrice).times(orders.length);
};

export async function checkAllowanceForTokenAmount(tokenContract, owner, spender, amount) {
  const allowanceAmount = await tokenContract.allowance(owner, spender).callAsync();
  return allowanceAmount.gte(amount);
}

export async function approveUnlimitedAmountForToken(tokenContract, owner, spender) {
  let tokenContractApprovalTxHash = null;
  try {
    tokenContractApprovalTxHash = await tokenContract
      .approve(spender, UNLIMITED_ALLOWANCE_IN_BASE_UNITS)
      .sendTransactionAsync({ from: owner });
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return tokenContractApprovalTxHash !== null;
}

export async function handleAllowance(tokenContract, owner, spender, amount) {
  const tokenHasEnoughAllowance = await checkAllowanceForTokenAmount(
    tokenContract,
    owner,
    spender,
    amount
  );

  if (!tokenHasEnoughAllowance) {
    const approveSuccess = await approveUnlimitedAmountForToken(tokenContract, owner, spender);
    return approveSuccess;
  }
  return true;
}

export async function approveUnlimitedAmountForDAI() {
  const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
  const { erc20Proxy } = contractWrappers.contractAddresses;
  const web3Wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();

  const [taker] = await web3Wrapper.getAvailableAddressesAsync();

  const daiTokenContract = new ERC20TokenContract(
    process.env.REACT_APP_DAI_ADDRESS,
    ZeroExWrapper.getInstance().getProvider()
  );
  const handleAllowanceResultDAI = await handleAllowance(
    daiTokenContract,
    taker,
    erc20Proxy,
    1000000
  );
  return handleAllowanceResultDAI;
}

export async function isOrderValid(contractWrappers, order) {
  const [{ orderStatus }, remainingFillableAmount, isValidSignature] =
    await contractWrappers.devUtils.getOrderRelevantState(order, order.signature).callAsync();

  return (
    orderStatus === OrderStatus.Fillable &&
    remainingFillableAmount.isGreaterThan(0) &&
    isValidSignature
  );
}

export async function batchFillOrders(bsptTokenAddress, orders, originalOrdersMap, formValues) {
  const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
  const { erc20Proxy } = contractWrappers.contractAddresses;
  const web3Wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();

  const [taker] = await web3Wrapper.getAvailableAddressesAsync();
  const daiAmount = Web3Wrapper.toBaseUnitAmount(formValues.daiAmount, 18);
  const daiTokenContract = new ERC20TokenContract(
    process.env.REACT_APP_DAI_ADDRESS,
    ZeroExWrapper.getInstance().getProvider()
  );
  const handleAllowanceResultDAI = await handleAllowance(
    daiTokenContract,
    taker,
    erc20Proxy,
    daiAmount
  );
  if (!handleAllowanceResultDAI) return null;

  const signedOrders = orders.map((o) => o.order);

  let validOrders = [];

  validOrders = signedOrders.filter(async (order) => {
    await isOrderValid(contractWrappers, order);
  });

  if (validOrders.length === 0) return null;

  const ordersToFill = [];
  const ordersAmountFill = [];
  const ordersSignatures = [];

  let remainingDAI = daiAmount;

  for (let i = 0; i < validOrders.length; i += 1) {
    const current = new BigNumber(validOrders[i].takerAssetAmount);
    if (remainingDAI.gte(current)) {
      ordersAmountFill.push(current);
      remainingDAI = remainingDAI.minus(current);
    } else {
      ordersAmountFill.push(remainingDAI);
      remainingDAI = new BigNumber(-1);
    }
    ordersSignatures.push(validOrders[i].signature);
    ordersToFill.push(originalOrdersMap[validOrders[i].signature]);
    if (remainingDAI.comparedTo(new BigNumber(0)) <= 0) {
      break;
    }
  }

  const txHash = await contractWrappers.exchange
    .batchFillOrKillOrders(ordersToFill, ordersAmountFill, ordersSignatures)
    .sendTransactionAsync({
      from: taker,
      ...TX_DEFAULTS,
      value: calculateProtocolFee(ordersToFill),
    });

  let totalTakerAssetFillAmount = new BigNumber(0);
  ordersAmountFill.forEach((takerAssetFillAmount) => {
    totalTakerAssetFillAmount = totalTakerAssetFillAmount.plus(takerAssetFillAmount);
  });

  return ordersToFill.map((order, index) => {
    return {
      fillerWallet: taker,
      signature: order.signature,
      txHash,
      volume: ordersAmountFill[index].toString(),
    };
  });
}

export async function createAndSignOrder(
  provider,
  contractWrappers,
  makerAddress,
  takerAddress,
  makerAssetAmount,
  takerAssetAmount,
  bsptTokenAddress,
  expirationTimeSeconds
) {
  const makerAssetData = assetDataUtils.encodeERC20AssetData(bsptTokenAddress);
  const takerAssetData = assetDataUtils.encodeERC20AssetData(process.env.REACT_APP_DAI_ADDRESS);

  const exchangeAddress = contractWrappers.contractAddresses.exchange;
  const randomSalt = generatePseudoRandomSalt();

  const order = {
    chainId,
    exchangeAddress,
    makerAddress,
    takerAddress,
    senderAddress: NULL_ADDRESS,
    feeRecipientAddress: NULL_ADDRESS,
    expirationTimeSeconds,
    salt: randomSalt,
    makerAssetAmount,
    takerAssetAmount,
    makerAssetData,
    takerAssetData,
    makerFeeAssetData: NULL_BYTES,
    takerFeeAssetData: NULL_BYTES,
    makerFee: BIGINT_ZERO,
    takerFee: BIGINT_ZERO,
  };

  const signedOrder = await signatureUtils.ecSignOrderAsync(provider, order, makerAddress);
  const orderValid = await isOrderValid(contractWrappers, signedOrder);
  if (orderValid) {
    return signedOrder;
  }
  return null;
}

export async function sellOrder(bsptTokenAddress, bsptAmount, daiAmount, expirationTimeSeconds) {
  console.log('Creating new order');
  const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
  const { erc20Proxy } = contractWrappers.contractAddresses;
  const web3Wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();
  const [maker] = await web3Wrapper.getAvailableAddressesAsync();
  console.log(maker);
  const bsptTokenContract = new ERC20TokenContract(
    bsptTokenAddress,
    ZeroExWrapper.getInstance().getProvider()
  );
  console.log(bsptTokenContract);
  const handleAllowanceResultBSPT = await handleAllowance(
    bsptTokenContract,
    maker,
    erc20Proxy,
    bsptAmount
  );
  if (!handleAllowanceResultBSPT) return null;

  const makerAssetAmount = Web3Wrapper.toBaseUnitAmount(new BigNumber(bsptAmount), DECIMALS);
  const takerAssetAmount = Web3Wrapper.toBaseUnitAmount(new BigNumber(daiAmount), DECIMALS);
  const signedOrder = await createAndSignOrder(
    ZeroExWrapper.getInstance().getProvider(),
    contractWrappers,
    maker,
    NULL_ADDRESS,
    makerAssetAmount,
    takerAssetAmount,
    bsptTokenAddress,
    expirationTimeSeconds
  );
  console.log(signedOrder);
  if (signedOrder) {
    return {
      ...signedOrder,
      makerTokenAddress: bsptTokenAddress,
      takerTokenAddress: process.env.REACT_APP_DAI_ADDRESS,
    };
  }
  return null;
}

export async function cancelOrder(order) {
  console.log('cancelOrder');
  console.log(order);

  const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
  const web3Wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();

  const [taker] = await web3Wrapper.getAvailableAddressesAsync();

  const transactionHash = await contractWrappers.exchange.cancelOrder(order).sendTransactionAsync({
    from: taker,
  });

  return transactionHash;

  /*   store.dispatch(showDialog({ type: DIALOG_TYPES.TRANSACTION_PENDING }));
  transaction
    .then((txHash) => {
      if (txHash) {
        store.dispatch(
          showDialog({
            type: DIALOG_TYPES.TRANSACTION_SUCCESS,
          })
        );
      }
    })
    .catch((error) => {
      store.dispatch(showDialog({ type: DIALOG_TYPES.TRANSACTION_FAILED, data: { error } }));
    }); */
}
