/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_OFFERING = gql`
  query Offerings($offeringId: String) {
    propertyOffering(id: $offeringId) {
      id
      maxInvestment
      minInvestment
      presaleStart
      presaleEnd
      saleStart
      saleEnd
      price
      property {
        token {
          name
          symbol
          totalSupply
        }
        capitalStacks(first: 1) {
          cap
        }
      }
    }
  }
`;

export const GET_OFFERINGS = gql`
  query Offerings {
    propertyOfferings(first: 1000) {
      id
      maxInvestment
      minInvestment
      presaleStart
      presaleEnd
      saleStart
      saleEnd
      price
      property {
        token {
          name
          symbol
          totalSupply
        }
        capitalStacks(first: 1) {
          cap
        }
      }
    }
  }
`;
