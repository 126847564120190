import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';

export const DIALOG_TYPES = {
  ADD_BANK_ACCOUNT: 1,
  ASSET_EXCHANGE: 2,
  BUY_OFFERING_TOKENS: 3,
  CHANGE_WALLET_NAME: 4,
  CLAIM_RENT: 5,
  CONNECT_WALLET: 6,
  DISCONNECT_WALLET: 7,
  EXCHANGE_BUY: 8,
  EXCHANGE_SELL: 9,
  PASSWORD_CHANGE: 10,
  UNLOCK_PROPERTY: 11,
  UNLOCK_TOKEN: 12,
  REQUEST_WITHDRAW: 13,
  CONFIRM_BUY: 14,
  CONNECT_WALLET_NEW: 15,
  WRONG_NETWORK: 16,
  TRANSACTION_FAILED: 17,
  TRANSACTION_PENDING: 18,
  TRANSACTION_SUCCESS: 19,
  EXCHANGE_NO_SELLERES: 20,
  ADD_FUNDS: 21,
};

const initialState = {
  activeDialog: null,
  activeDialogData: {},
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    showDialog(state, action) {
      const { type, data } = action.payload;
      state.activeDialog = type;
      state.activeDialogData = data;
    },
    hideDialogs(state) {
      state.activeDialog = null;
      state.activeDialogData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { hideDialogs, showDialog } = dialogsSlice.actions;
export default dialogsSlice.reducer;
