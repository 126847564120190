import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export function initI18n(store) {
  const {
    company: { company },
    language: { selectedLanguage },
  } = store.getState();

  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      lng: selectedLanguage[company] ?? '',
      debug: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // Use this for cache busting reasons.
      backend: { queryStringParams: { v: process.env.REACT_APP_VERSION } },
    });
}

export default i18n;
