/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import API from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import i18n from 'i18n';
import { RESET_STATE } from 'redux/sharedActions';

const initialState = {
  token: null,
  groups: [],
  site: '',
  isAuthenticating: false,
  isRequestingNewPassword: false,
  needAgreementForNewCP: false,
  expireAt: null,
  errorMessage: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginRequest(state) {
      state.isAuthenticating = true;
    },
    loginFailure(state, action) {
      const { reason } = action.payload;
      state.isAuthenticating = false;
      state.errorMessage = reason;
      state.needAgreementForNewCP =
        reason === 'You need to agree to share your personal information with this company';
    },
    loginSuccess(state, action) {
      const { jwt } = action.payload;
      const { groups, exp, company } = jwtDecode(jwt);
      state.token = jwt;
      state.groups = groups;
      state.isAuthenticating = false;
      state.expireAt = exp;
      state.site = company;
    },
    loginCancel(state) {
      state.needAgreementForNewCP = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userLoginRequest(loginData) {
  return (dispatch) => {
    dispatch(loginRequest());
    return API.post('/login', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === 'SUCCESS') {
          dispatch(loginSuccess(data));
        } else {
          dispatch(loginFailure(status));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(loginFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userLoginRequestNewCP(loginData) {
  return (dispatch) => {
    dispatch(loginRequest());
    return API.post('/register/new-company', loginData)
      .then((response) => {
        const { data } = response;
        const { status } = data;
        if (status.status === 'SUCCESS') {
          dispatch(loginSuccess(data));
        } else {
          dispatch(loginFailure(status));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(loginFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const { loginRequest, loginFailure, loginSuccess, loginCancel } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
