import { createTheme } from '@material-ui/core/styles';

import variants from './variants.json';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';
import props from './props';
import shadows from './shadows';

const theme = (variant) => {
  return createTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      palette: variant.palette,
      body: variant.body,
      sidebar: variant.sidebar,
    },
    { id: variant.id }
  );
};

const themes = variants.themes.map((variant) => theme(variant));

export default themes;
