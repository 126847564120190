import axios from 'axios';

const STATUS_FAILURE = 'FAILURE';
const STATUS_SUCCESS = 'SUCCESS';
const STATUS = [STATUS_FAILURE, STATUS_SUCCESS];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

/* api.interceptors.request.use((request) => {
  console.log('Starting Request', request);
  return request;
});

api.interceptors.response.use((response) => {
  console.log('Response:', response);
  return response;
}); */

export { STATUS, STATUS_FAILURE, STATUS_SUCCESS };

export default api;
