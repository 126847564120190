import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { cpToTitle } from 'utils/company';

export default function CustomHead() {
  const company = useSelector((store) => store.company.company);

  return (
    <Helmet>
      <title>{cpToTitle(company)}</title>
      <link id="favicon" rel="shortcut icon" href={`/static/img/favicons/${company}.png`} />
    </Helmet>
  );
}
