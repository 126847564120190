import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  min-width: 100%;
`;

const Loader = ({ color, size }) => {
  return (
    <Root>
      <CircularProgress size={size} m={2} color={color} />
    </Root>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Loader.defaultProps = {
  color: 'primary',
  size: 40,
};

export default memo(Loader);
