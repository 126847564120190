/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getWeb3ReactContext } from '@web3-react/core';

const withWeb3 = (Component) => {
  return (props) => {
    const Web3ReactContext = getWeb3ReactContext();

    return (
      <Web3ReactContext.Consumer>
        {(context) => <Component {...props} web3Context={context} />}
      </Web3ReactContext.Consumer>
    );
  };
};

export { withWeb3 };
