import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { getWeb3Library } from 'utils/web3';
import App from './App';
import { persistor, store } from './redux/index';
import Loader from './components/custom/Loader';

import './fonts/font.scss';

if (!process.env.REACT_APP_DAI_ADDRESS) throw new Error('REACT_APP_DAI_ADDRESS not set');

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Web3ReactProvider getLibrary={getWeb3Library}>
        <App />
      </Web3ReactProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
