/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import languagesLib from '@cospired/i18n-iso-languages';
import { resetState } from 'utils/state';
import { gqlclient, GET_COMPANY_QUERY } from 'query';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import API from 'utils/api';
import { RESET_STATE } from './sharedActions';

languagesLib.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'));

const initialState = {
  company: 'blocksquare',
  cpBytes: '',
  policy: '',
  terms: '',
  languages: [],
  offeringCurrency: 'EUR',
  isUpdatingCompany: false,
  updateCompanyErrorMessage: null,
  isRequestingOnChainData: false,
  onChainRequestErrorMessage: null,
  isRequestingLanguages: false,
  languagesRequestError: null,
  isRequestingTermsAndPolicy: false,
  errorRequestingTermsAndPolicy: null,
  isRequestingCurrency: false,
  errorRequestingCurrency: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyChangeRequest(state) {
      state.isUpdatingCompany = true;
    },
    companyChangeSuccess(state, action) {
      const { company } = action.payload;
      state.company = company;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = null;
    },
    companyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingCompany = false;
      state.updateCompanyErrorMessage = reason;
    },
    companyOnChainRequest(state) {
      state.isRequestingOnChainData = true;
    },
    companyOnChainSuccess(state, action) {
      const { cpBytes } = action.payload;
      state.cpBytes = cpBytes;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = null;
    },
    companyOnChainFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingOnChainData = false;
      state.onChainRequestErrorMessage = reason;
    },
    companyLanguageRequest(state) {
      state.isRequestingLanguages = true;
    },
    companyLanguageSuccess(state, action) {
      const { languages } = action.payload;
      state.languages = languages;
      state.isRequestingLanguages = false;
    },
    companyLanguageFailure(state, action) {
      const { reason } = action.payload;
      state.languagesRequestError = reason;
      state.isRequestingLanguages = false;
    },
    companyTermsAndPolicyChangeRequest(state) {
      state.isRequestingTermsAndPolicy = true;
    },
    companyTermsAndPolicyChangeSuccess(state, action) {
      const { terms, policy } = action.payload;
      state.terms = terms;
      state.policy = policy;
      state.isRequestingTermsAndPolicy = false;
    },
    companyTermsAndPolicyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.errorRequestingTermsAndPolicy = reason;
      state.isRequestingTermsAndPolicy = false;
    },
    companyCurrencyRequest(state) {
      state.isRequestingCurrency = true;
    },
    companyCurrencySuccess(state, action) {
      const { offeringCurrency } = action.payload;
      state.offeringCurrency = offeringCurrency;
      state.isRequestingCurrency = false;
    },
    companyCurrencyFailure(state, action) {
      const { reason } = action.payload;
      state.errorRequestingCurrency = reason;
      state.isRequestingCurrency = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function setCompany(company) {
  return async (dispatch, getState) => {
    const {
      authentication: { site, token },
      company: { company: currentCompany, languages, terms, policy },
    } = getState();

    // Destroy active session and reset state
    if (token && (!site || company !== site)) {
      return dispatch(resetState());
    }

    if (company !== currentCompany || !policy || !terms) {
      dispatch(queryCompanyTermsAndPolicy(company));
    }

    if (token) {
      if (company !== currentCompany || languages.length === 0) {
        dispatch(queryCompanyLanguages(company));
      }

      dispatch(queryCompanyCurrency(company));

      dispatch(companyOnChainRequest());
      gqlclient
        .query({
          query: GET_COMPANY_QUERY,
          variables: {
            companyName: company,
          },
        })
        .then((result) => {
          const {
            data: { certifiedPartners },
          } = result;
          if (certifiedPartners && certifiedPartners.length > 0) {
            const certifiedPartner = certifiedPartners[0];
            dispatch(companyOnChainSuccess({ cpBytes: certifiedPartner.id }));
          }
        })
        .catch((error) => {
          dispatch(companyOnChainFailure({ reason: error.message }));
        });
    }

    dispatch(companyChangeRequest());
    return dispatch(companyChangeSuccess({ company }));
  };
}

export function queryCompanyLanguages(company) {
  return async (dispatch, getState) => {
    dispatch(companyLanguageRequest());
    return API.get(`/company/${company}/languages`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const tempLanguages = data.map((language) => {
          const tempLanguage = {};
          tempLanguage.language = language.toLowerCase();
          tempLanguage.countryCode = languagesLib.getAlpha2Code(tempLanguage.language, 'en');
          return tempLanguage;
        });
        dispatch(companyLanguageSuccess({ languages: tempLanguages }));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(companyLanguageFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function queryCompanyTermsAndPolicy(company) {
  return async (dispatch) => {
    dispatch(companyTermsAndPolicyChangeRequest());
    return API.get(`/company/${company}/terms-policy`, {})
      .then((response) => {
        const {
          data: { terms, policy },
        } = response;

        dispatch(companyTermsAndPolicyChangeSuccess({ terms, policy }));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(companyTermsAndPolicyChangeFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function queryCompanyCurrency(company) {
  return async (dispatch) => {
    dispatch(companyCurrencyRequest());
    return API.get(`/company/${company}/currency`, {})
      .then((response) => {
        const {
          data: { currency },
        } = response;
        console.log(currency);
        dispatch(companyCurrencySuccess({ offeringCurrency: currency }));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(companyCurrencyFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  companyChangeRequest,
  companyChangeSuccess,
  companyChangeFailure,
  companyOnChainRequest,
  companyOnChainSuccess,
  companyOnChainFailure,
  companyLanguageRequest,
  companyLanguageSuccess,
  companyLanguageFailure,
  companyTermsAndPolicyChangeRequest,
  companyTermsAndPolicyChangeSuccess,
  companyTermsAndPolicyChangeFailure,
  companyCurrencyRequest,
  companyCurrencySuccess,
  companyCurrencyFailure,
} = companySlice.actions;
export default companySlice.reducer;
