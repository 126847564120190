import { combineReducers } from '@reduxjs/toolkit';

import authenticationReducer from './authentication';
import companyReducer from './company';
import countriesReducer from './countries';
import currencyReducer from './currency';
import dialogsReducer from './dialogs';
import passwordReducer from './password';
import propertiesReducer from './properties';
import portfolioReducer from './portfolio';
import languageReducer from './language';
import registrationReducer from './registration';
import tradeReducer from './trade';
import userReducer from './user';
import userBankAccountReducer from './userBankAccount';
import userBankTransactionsReducer from './userBankTransactions';
import userKycReducer from './userKyc';
import userWalletReducer from './userWallet';
import notificationReducer from './notification';
import walletReducer from './wallet';
import routeReducer from './route';
import blockchainTxNotificationReducer from './blockchainTxNotification';

const appReducer = combineReducers({
  authentication: authenticationReducer,
  company: companyReducer,
  countries: countriesReducer,
  currency: currencyReducer,
  dialogs: dialogsReducer,
  password: passwordReducer,
  properties: propertiesReducer,
  portfolio: portfolioReducer,
  registration: registrationReducer,
  trade: tradeReducer,
  language: languageReducer,
  user: userReducer,
  userBankAccount: userBankAccountReducer,
  userBankTransactions: userBankTransactionsReducer,
  userKyc: userKycReducer,
  userWallet: userWalletReducer,
  route: routeReducer,
  notification: notificationReducer,
  wallet: walletReducer,
  blockchainTxNotification: blockchainTxNotificationReducer,
});

export default appReducer;
