/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs } from 'redux/dialogs';
import i18n from 'i18n';
import API, { STATUS_SUCCESS } from 'utils/api';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isSendingTransaction: false,
  sendTransactionErrorMessage: null,
  userTransactions: [],
  userOfferings: [],
  isRequestingUserOfferings: false,
  requestingUserOfferingsError: null,
  isRequestingTransactions: false,
  requestingTransactionsErrorMessage: null,
  isSendingWithdrawal: false,
  sendWithdrawalErrorMessage: null,
};

const userBankTransactionsSlice = createSlice({
  name: 'userBankTransactions',
  initialState,
  reducers: {
    sendTransactionRequest(state) {
      state.isSendingTransaction = true;
    },
    sendTransactionSuccess(state) {
      state.isSendingTransaction = false;
      state.sendTransactionErrorMessage = null;
    },
    sendTransactionFailure(state, action) {
      const { error } = action.payload;
      state.isSendingTransaction = false;
      state.sendTransactionErrorMessage = error;
    },
    requestUserTransactionsRequest(state) {
      state.isRequestingTransactions = true;
    },
    requestUserTransactionsSuccess(state, action) {
      state.isRequestingTransactions = false;
      state.userTransactions = action.payload;
    },
    requestUserTransactionsFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingTransactions = false;
      state.requestingTransactionsErrorMessage = reason;
    },
    requestUserOfferingsRequest(state) {
      state.isRequestingUserOfferings = true;
    },
    requestUserOfferingsSuccess(state, action) {
      state.isRequestingUserOfferings = false;
      state.userOfferings = action.payload;
    },
    requestUserOfferingsFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingUserOfferings = false;
      state.requestingUserOfferingsError = reason;
    },
    sendWithdrawal(state) {
      state.isSendingWithdrawal = true;
    },
    sendWithdrawalSuccess(state) {
      state.isSendingWithdrawal = false;
      state.sendWithdrawalErrorMessage = null;
    },
    sendWithdrawalFailure(state, action) {
      const { error } = action.payload;
      state.isSendingWithdrawal = false;
      state.sendWithdrawalErrorMessage = error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function sendBankTransaction(transactionData) {
  return async (dispatch, getState) => {
    dispatch(sendWithdrawal());
    return API.post('/bank-transaction', transactionData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(sendTransactionSuccess());
          dispatch(hideDialogs());
          dispatch(createNotification('TODO: Better success message'));
        } else {
          dispatch(sendTransactionFailure(status));
          dispatch(hideDialogs());
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(sendTransactionFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}
export function userTransactionsHistoryFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(requestUserTransactionsRequest());
    return API.get('/bank/transaction', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(requestUserTransactionsSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(requestUserTransactionsFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userOfferingsHistoryFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(requestUserOfferingsRequest());
    return API.get('/initial-sale/investment', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(requestUserOfferingsSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(requestUserOfferingsFailure({ reason: networkError }));
        // TODO: change notification
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function sendWithdrawalTransaction(hash) {
  return async (dispatch, getState) => {
    dispatch(sendWithdrawal());
    return API.post(
      '/bank/withdrawal',
      { transactionHash: hash },
      {
        headers: {
          Authorization: `Bearer ${getState().authentication.token}`,
        },
      }
    )
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(sendWithdrawalSuccess());
          dispatch(hideDialogs());
          dispatch(createNotification('Withdrawal submitted'));
        } else {
          dispatch(sendWithdrawalFailure(status));
          dispatch(hideDialogs());
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(sendWithdrawalFailure({ reason: networkError }));
        dispatch(hideDialogs());
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  sendTransactionFailure,
  sendTransactionRequest,
  sendTransactionSuccess,
  requestUserTransactionsRequest,
  requestUserTransactionsSuccess,
  requestUserTransactionsFailure,
  requestUserOfferingsRequest,
  requestUserOfferingsSuccess,
  requestUserOfferingsFailure,
  sendWithdrawal,
  sendWithdrawalFailure,
  sendWithdrawalSuccess,
} = userBankTransactionsSlice.actions;
export default userBankTransactionsSlice.reducer;
