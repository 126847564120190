import { ApolloClient, InMemoryCache } from '@apollo/client';

if (!process.env.REACT_APP_BLOCKSQUARE_SUBGRAPH_ENDPOINT)
  throw new Error('REACT_APP_BLOCKSQUARE_SUBGRAPH_ENDPOINT not defined');

const client = new ApolloClient({
  uri: process.env.REACT_APP_BLOCKSQUARE_SUBGRAPH_ENDPOINT,
  cache: new InMemoryCache(),
});

export default client;
