/* eslint-disable import/prefer-default-export */
import ZeroExWrapper from 'utils/ZeroExWrapper';
import Web3 from 'web3';

let web3 = null;
let selectedProvider = null;

function getWeb3Library(provider) {
  if (!web3 || provider !== selectedProvider) {
    web3 = new Web3(provider);
    selectedProvider = provider;
    ZeroExWrapper.getInstance().setWrappers(provider);
  }
  return web3;
}

function getWeb3() {
  return web3;
}

export { getWeb3Library, getWeb3 };
