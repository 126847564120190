/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import ZeroExWrapper from 'utils/ZeroExWrapper';
import { createNotification } from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  hasPendingTransaction: false,
};

const blockchainTxNotificationSlice = createSlice({
  name: 'blockchainTxNotification',
  initialState,
  reducers: {
    transactionRequest(state) {
      state.hasPendingTransaction = true;
    },
    transactionSuccess(state) {
      state.hasPendingTransaction = false;
    },
    transactionFailure(state) {
      state.hasPendingTransaction = false;
    },
    extraReducers: (builder) => {
      builder.addCase(RESET_STATE, () => {
        return { ...initialState };
      });
    },
  },
});

export function listenToTransaction(tx, kind) {
  return (dispatch) => {
    dispatch(transactionRequest());
    const wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();
    wrapper.awaitTransactionMinedAsync(tx, 10000, 600000).then((transaction) => {
      if (transaction.status === 1) {
        dispatch(transactionSuccess());
        const notificationMessage = i18n.t('notification_transaction_finished', { kind });
        dispatch(createNotification(notificationMessage));
      } else if (transaction.status === 0) {
        dispatch(transactionFailure());
        const notificationMessage = i18n.t('notification_transaction_failed', { kind });
        dispatch(createNotification(notificationMessage, 'error'));
      }
    });
  };
}

export const { transactionRequest, transactionSuccess, transactionFailure } =
  blockchainTxNotificationSlice.actions;
export default blockchainTxNotificationSlice.reducer;
