import React from 'react';
import { connect } from 'react-redux';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import CustomHead from 'components/custom/CustomHead';

import themes from './theme';
import Routes from './routes/Routes';

function App(props) {
  const {
    company: { company },
  } = props;

  const currentTheme = themes.find((item) => {
    return item.id === company;
  });

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={currentTheme}>
        <ThemeProvider theme={currentTheme}>
          <CustomHead />
          <Routes />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default connect((store) => ({ company: store.company }))(App);
