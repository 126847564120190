/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  languages: [],
  selectedLanguage: { blocksquare: 'en' },
  isUpdatingLanguage: false,
  isFetchingAvailableLanguages: false,
  updateLanguageErrorMessage: null,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    languageChangeRequest(state) {
      state.isUpdatingLanguage = true;
    },
    languageChangeSuccess(state, action) {
      const { company, language } = action.payload;
      state.selectedLanguage[company] = language;
      state.isUpdatingLanguage = false;
      state.updateLanguageErrorMessage = null;
    },
    languageChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingLanguage = false;
      state.updateLanguageErrorMessage = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, (state) => {
      const newState = { ...initialState };
      newState.selectedLanguage = state.selectedLanguage;
      return newState;
    });
  },
});

export function changeLanguage(i18n, lng, showNotification = true) {
  return async (dispatch, getState) => {
    const {
      language: { language },
      company: { company },
    } = getState();

    if (language !== lng) {
      dispatch(languageChangeRequest());
      i18n.changeLanguage(lng);
      dispatch(languageChangeSuccess({ company, language: lng }));
      if (showNotification) dispatch(createNotificationByType(NOTIFICATION_TYPES.SETTINGS_SAVED));
    }
  };
}

export const { languageChangeRequest, languageChangeSuccess, languageChangeFailure } =
  languageSlice.actions;
export default languageSlice.reducer;

// TODO: Check why payment information is empty for offerings dialog, also check input step as well as rounding of validaiton
