import { getWeb3 } from './helper';

const getEtherBalance = async (userAccount) => {
  const library = getWeb3();
  const etherBalance = library.eth.getBalance(userAccount);
  return etherBalance;
};

// eslint-disable-next-line import/prefer-default-export
export { getEtherBalance };
