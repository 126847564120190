const overrides = {
  MuiCssBaseline: {
    '@global': {
      'html, body': {
        height: '100%',
      },
      '#root': {
        minHeight: '100%',
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },

    title: {
      fontSize: '1.1rem',
      fontWeight: '500',
      lineHeight: '1.2',
      fontFamily:
        "'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },

    subheader: {
      fontSize: '0.9rem',
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: 'transparent',
    },
    rounded: {
      borderRadius: '0.6rem',
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiDialog: {
    paper: {
      background: '#fff',
    },
  },
  MuiDialogContent: {
    dividers: {
      padding: '1rem 2.5rem 2.5rem 2.5rem',
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',
    },
  },
  MuiButton: {
    root: {
      height: '2.5rem',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
    contained: {
      /* padding: '1rem 1.5rem', */
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
    containedSecondary: {
      /* padding: '1rem 1.5rem', */
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
    outlined: {
      /* padding: '1rem 1.5rem', */
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },

    outlinedSecondary: {
      border: '0.0625rem solid rgba(255, 255, 255, 0.8)',
    },
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
  MuiCardActionArea: {
    focusHighlight: {
      opacity: '0 !important',
    },
  },
  MuiMenu: {
    paper: {
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
  MuiLink: {
    root: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};

export default overrides;
