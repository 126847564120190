import { personalMessageSign } from './BlocksquareBlockchainUtils';

const ROLE_ADMIN = 'admin';
const ROLE_BUSINESS = 'business';
const ROLE_USER = 'user';

const ROLES = [ROLE_ADMIN, ROLE_BUSINESS, ROLE_USER];

export { ROLES, ROLE_ADMIN, ROLE_BUSINESS, ROLE_USER };

const USER_BANK_TRANSACTION_CONFIRMED = 'CONFIRMED';
const USER_BANK_TRANSACTION_FAILED = 'FAILED';
const USER_BANK_TRANSACTION_PENDING = 'PENDING';

const USER_BANK_TRANSACTIONS = [
  USER_BANK_TRANSACTION_CONFIRMED,
  USER_BANK_TRANSACTION_PENDING,
  USER_BANK_TRANSACTION_FAILED,
];

export {
  USER_BANK_TRANSACTIONS,
  USER_BANK_TRANSACTION_CONFIRMED,
  USER_BANK_TRANSACTION_PENDING,
  USER_BANK_TRANSACTION_FAILED,
};

const KYC_ACCEPTED = 'ACCEPTED';
const KYC_DENIED = 'DENIED';
const KYC_NEW = 'NEW';
const KYC_PENDING = 'PENDING';
const KYC_WHITELISTED = 'WHITELISTED';

const WALLET_CONFIRMED = 'CONFIRMED';
const WALLET_DENIED = 'DENIED';
const WALLET_ETH_CHECKED = 'ETH_CHECKED';
const WALLET_PENDING = 'PENDING';
const WALLET_WHITELISTED = 'WHITELISTED';

const KYC_STATUS = [KYC_ACCEPTED, KYC_DENIED, KYC_NEW, KYC_PENDING, KYC_WHITELISTED];
const WALLET_STATUS = [
  WALLET_CONFIRMED,
  WALLET_DENIED,
  WALLET_ETH_CHECKED,
  WALLET_PENDING,
  WALLET_WHITELISTED,
];

export { KYC_STATUS, KYC_ACCEPTED, KYC_DENIED, KYC_NEW, KYC_PENDING, KYC_WHITELISTED };
export {
  WALLET_STATUS,
  WALLET_CONFIRMED,
  WALLET_DENIED,
  WALLET_ETH_CHECKED,
  WALLET_PENDING,
  WALLET_WHITELISTED,
};

export async function createAndSignWalletObject(walletType, userAddress) {
  const message = `I'm the owner of ${userAddress}.`;
  const signedMessage = await personalMessageSign(walletType, userAddress, message);

  return {
    msg: message,
    signature: signedMessage,
    walletType,
  };
}
