/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API from 'utils/api';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isInitialState: true,
  isFetchingUserInformations: false,
  email: '',
  birth: '',
  country: '',
  firstName: '',
  lastName: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserInformationsRequest(state) {
      state.isFetchingUserInformations = true;
    },
    getUserInformationsSuccess(state, action) {
      state.isFetchingUserInformations = false;
      const { birth, country, email, firstName, lastName } = action.payload;
      state.birth = birth || '';
      state.country = country || '';
      state.email = email;
      state.firstName = firstName || '';
      state.lastName = lastName || '';
    },
    getUserInformationsFailure(state) {
      state.isFetchingUserInformations = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function userInformationsFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(getUserInformationsRequest());
    return API.get('/user', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(getUserInformationsSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(getUserInformationsFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  getUserInformationsFailure,
  getUserInformationsRequest,
  getUserInformationsSuccess,
  updateUserPersonalInformationsFailure,
  updateUserPersonalInformationsRequest,
  updateUserPersonalInformationsSuccess,
} = userSlice.actions;
export default userSlice.reducer;
