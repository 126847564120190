/* eslint-disable no-use-before-define */
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createNotificationByType, NOTIFICATION_TYPES } from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  selectedCurrency: { blocksquare: 'EUR' },
  daiToFiatRates: {},
  isFetchingRate: false,
  isUpdating: false,
  isFetching: false,
  updateErrorMessage: null,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    rateFetchRequest(state) {
      state.isFetchingRate = true;
    },
    rateFetchSuccess(state, action) {
      const { dai } = action.payload;
      state.isFetchingRate = false;
      state.daiToFiatRates = dai;
    },
    rateFetchFailure(state) {
      state.isFetchingRate = false;
    },
    changeCurrencyRequest(state) {
      state.isUpdating = true;
    },
    currencyChangeSuccess(state, action) {
      const { company, currency } = action.payload;
      state.selectedCurrency[company] = currency;
      state.isUpdating = false;
      state.updateErrorMessage = null;
    },
    currencyChangeFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdating = false;
      state.updateErrorMessage = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, (state) => {
      const newState = { ...initialState };
      newState.selectedCurrency = state.selectedCurrency;
      return newState;
    });
  },
});

export function getExchangeRate() {
  return async (dispatch) => {
    dispatch(rateFetchRequest());
    axios
      .get(`https://api.coingecko.com/api/v3/simple/price?ids=dai&vs_currencies=usd%2Ceur`)
      .then((response) => {
        const { data } = response;
        dispatch(rateFetchSuccess(data));
      })
      .catch(() => dispatch(rateFetchFailure()));
  };
}

export function changeCurrency(company, currency) {
  return async (dispatch) => {
    dispatch(changeCurrencyRequest());
    dispatch(currencyChangeSuccess({ company, currency }));
    dispatch(createNotificationByType(NOTIFICATION_TYPES.SETTINGS_SAVED));
  };
}

export const {
  changeCurrencyRequest,
  currencyChangeSuccess,
  currencyChangeFailure,
  rateFetchRequest,
  rateFetchSuccess,
  rateFetchFailure,
} = currencySlice.actions;

export default currencySlice.reducer;
