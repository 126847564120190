/* eslint-disable import/prefer-default-export */
export function cpToTitle(cp) {
  switch (cp) {
    case 'armsmcgregor':
      return 'Arms &McGregor International Realty®';
    case 'consorto':
      return 'Consorto';
    case 'lumifund':
      return 'Lumifund';
    case 'mountx':
      return 'Mountx';
    case 'terramint':
      return 'Terramint';
    case 'bravo':
      return 'Bravo';
    case 'tokeniza':
      return 'Tokeniza';
    default:
      return 'Blocksquare';
  }
}
