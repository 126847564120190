/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_COMPANY_QUERY = gql`
  query Company($companyName: String) {
    certifiedPartners(first: 1, where: { name: $companyName }) {
      id
    }
  }
`;
