/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import API from 'utils/api';
import { batchFillOrders, isOrderValid } from 'utils/order';
import { hideDialogs } from 'redux/dialogs';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import ZeroExWrapper from 'utils/ZeroExWrapper';
import { listenToTransaction } from './blockchainTxNotification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  errorMessageHistory: null,
  errorMessageOrders: null,
  errorMessagePostFillOrder: null,
  errorMessagePostSellOrder: null,
  history: [],
  isPostingFillOrder: false,
  isPostingSellOrder: false,
  isRequestingHistory: false,
  isRequestingOrders: false,
  orders: [],
  userHistory: [],
  isRequestingUserHistory: false,
  errorMessageUserHistory: null,
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    historyFetchRequest(state) {
      state.isRequestingHistory = true;
    },
    historyFetchSuccess(state, action) {
      state.isRequestingHistory = true;
      state.history = action.payload;
      state.errorMessageHistory = null;
    },
    historyFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingHistory = true;
      state.errorMessageHistory = reason;
    },
    postFillOrderRequest(state) {
      state.isPostingFillOrder = true;
    },
    postFillOrderSuccess(state) {
      state.isPostingFillOrder = false;
    },
    postFillOrderFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessagePostFillOrder = reason;
      state.isPostingFillOrder = false;
    },
    postSellOrderRequest(state) {
      state.isPostingSellOrder = true;
    },
    postSellOrderSuccess(state) {
      state.isPostingSellOrder = false;
    },
    postSellOrderFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessagePostSellOrder = reason;
      state.isPostingSellOrder = false;
    },
    ordersFetchRequest(state) {
      state.isRequestingOrders = true;
    },
    ordersFetchSuccess(state, action) {
      state.isRequestingOrders = false;
      state.orders = action.payload;
      state.errorMessageOrders = null;
    },
    ordersFetchFailure(state, action) {
      const { reason } = action.payload;
      state.errorMessageOrders = reason;
      state.isRequestingOrders = false;
    },
    userHistoryFetchRequest(state) {
      state.isRequestingUserHistory = true;
    },
    userHistoryFetchSuccess(state, action) {
      state.isRequestingUserHistory = true;
      state.userHistory = action.payload;
      state.errorMessageUserHistory = null;
    },
    userHistoryFetchFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingUserHistory = true;
      state.errorMessageUserHistory = reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function tokenHistoryFetchRequest(tokenAddress) {
  return async (dispatch, getState) => {
    dispatch(historyFetchRequest());
    return API.get(`/trade/history/${tokenAddress}`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(historyFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(historyFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function userTradeHistoryFetchRequest() {
  return async (dispatch, getState) => {
    dispatch(userHistoryFetchRequest());
    return API.get('/trade/history/user', {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(userHistoryFetchSuccess(data));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(userHistoryFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

// Filter with async function
async function filter(arr, callback) {
  // eslint-disable-next-line symbol-description
  const fail = Symbol();
  return (
    await Promise.all(arr.map(async (item) => ((await callback(item)) ? item : fail)))
  ).filter((i) => i !== fail);
}

export function tokenOrdersFetchRequest(tokenAddress) {
  return async (dispatch, getState) => {
    dispatch(ordersFetchRequest());
    return API.get(`/trade/${tokenAddress}`, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then(async (response) => {
        const { data } = response;
        const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
        const validOrders = await filter(data, async (order) => {
          const isValid = await isOrderValid(contractWrappers, order);
          return isValid;
        });

        dispatch(ordersFetchSuccess(validOrders));
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(ordersFetchFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function postSellOrder(orderData) {
  return async (dispatch, getState) => {
    dispatch(postSellOrderRequest());
    if (orderData === null) {
      const orderError = i18n.t('notification_create_order_error');
      dispatch(postSellOrderFailure({ reason: orderError }));
      dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_CREATION_ERROR));
      return null;
    }
    return API.post('/trade', orderData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(postSellOrderSuccess());
          dispatch(hideDialogs());
          dispatch(tokenHistoryFetchRequest(orderData.makerTokenAddress));
          dispatch(tokenOrdersFetchRequest(orderData.makerTokenAddress));
          dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_PLACED));
        } else {
          dispatch(postSellOrderFailure(data));
          dispatch(hideDialogs());
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(postSellOrderFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
        dispatch(hideDialogs());
      });
  };
}

export function fillOrders(bsptTokenAddress, orders, originalOrdersMap, formValues) {
  return async (dispatch) => {
    dispatch(postFillOrderRequest());
    const tradeData = await batchFillOrders(
      bsptTokenAddress,
      orders,
      originalOrdersMap,
      formValues
    );
    dispatch(listenToTransaction(tradeData[0].txHash, 'orders filled'));
    if (tradeData === null) {
      const orderError = i18n.t('notification_create_order_error');
      dispatch(postFillOrderFailure({ reason: orderError }));
      dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_CREATION_ERROR));
      return null;
    }
    dispatch(postFillOrderSuccess());
    dispatch(tokenOrdersFetchRequest(bsptTokenAddress));
    dispatch(hideDialogs());
    return dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_FILLED));
    /* return API.post('/trade/fill-order', tradeData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        if (data.status === 'SUCCESS') {
          dispatch(postFillOrderSuccess());
          dispatch(createNotificationByType(NOTIFICATION_TYPES.ORDER_FILLED));
        } else {
          dispatch(postFillOrderFailure(data));
          dispatch(createNotification(data.reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(postFillOrderFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      }); */
  };
}

export const {
  historyFetchFailure,
  historyFetchRequest,
  historyFetchSuccess,
  ordersFetchFailure,
  ordersFetchRequest,
  ordersFetchSuccess,
  postFillOrderFailure,
  postFillOrderRequest,
  postFillOrderSuccess,
  postSellOrderFailure,
  postSellOrderRequest,
  postSellOrderSuccess,
  userHistoryFetchFailure,
  userHistoryFetchSuccess,
  userHistoryFetchRequest,
} = tradeSlice.actions;
export default tradeSlice.reducer;
