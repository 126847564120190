import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import {
  auth as authRoutes,
  callback as callbackRoutes,
  dashboard as dashboardRoutes,
  kyc as kycRoutes,
} from './index';
import Loader from '../components/custom/Loader';

const AuthLayout = lazy(() => import('../layouts/Auth'));
const DashboardLayout = lazy(() => import('../layouts/Dashboard'));
const KycLayout = lazy(() => import('../layouts/Kyc'));
const RouteWrapper = lazy(() => import('../components/RouteWrapper'));
const Page404 = lazy(() => import('../pages/auth/Page404'));
const NoMobile = lazy(() => import('../pages/auth/NoMobile'));

// Adjust the companies that should be valid on the dapp here
const VALID_COMPANIES = process.env.REACT_APP_VALID_COMPANIES.split(',').join('|');

const renderSingleRoute = (Layout, route) => {
  return (
    <RouteWrapper
      key={route.id}
      path={`/:company(${VALID_COMPANIES})${route.path}`}
      exact
      layout={Layout}
      component={route.component}
      restrictedTo={route.restrictedTo}
    />
  );
};

const renderRoutes = (Layout, routes) =>
  routes.map((route) => {
    let renderedRoutes = [];
    if (route.children) {
      renderedRoutes = route.children.map((childrenRoute) => {
        return renderSingleRoute(Layout, childrenRoute);
      });
    }
    renderedRoutes.push(renderSingleRoute(Layout, route));
    return renderedRoutes;
  });

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader size="6rem" />}>
        <Switch>
          <Route exact path="/404" component={Page404} />

          {renderRoutes(DashboardLayout, dashboardRoutes)}
          {renderRoutes(DashboardLayout, kycRoutes)}
          {renderRoutes(KycLayout, callbackRoutes)}
          {renderRoutes(AuthLayout, authRoutes)}
          <Route exact path={`/:company(${VALID_COMPANIES})/404`} component={Page404} />
          <Route exact path={`/:company(${VALID_COMPANIES})/noMobile`} component={NoMobile} />
          <Route
            exact
            path={`/:company(${VALID_COMPANIES})/*`}
            render={(props) => <Redirect to={`/${props.match.params.company}/404`} />}
          />
          <Redirect exact from="/" to={`/${process.env.REACT_APP_DEFAULT_COMPANY}`} />
          <Redirect from="*" to="/404" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
