import { BigNumber } from 'bignumber.js';

export function displayHexString(address, length = 6) {
  const addressLength = address.length;

  const firstPart = address.substring(0, length);
  const lastPart = address.substring(addressLength - length, addressLength);
  return `${firstPart}...${lastPart}`;
}

export function formatBigNumber(valuation) {
  let number = 0;
  if (valuation) {
    number = new BigNumber(valuation).dividedBy(10 ** 18).toNumber();
  }
  return number;
}

export function formatBigNumberWithoutDivision(valuation) {
  let number = 0;
  if (valuation) {
    number = new BigNumber(valuation).toNumber();
  }
  return number;
}

export function getCurrentValuation(tokenValuation, propertyValuation, totalSupply) {
  return (tokenValuation * totalSupply + propertyValuation * (100000 - totalSupply)) / 100000;
}

export function getCurrentPropertyUserTokenValuation(
  tokenValuation,
  propertyValuation,
  totalSupply,
  userTokenAmount
) {
  const currentPropertyValue = getCurrentValuation(tokenValuation, propertyValuation, totalSupply);
  return currentPropertyValue / userTokenAmount;
}
