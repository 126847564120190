/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { hideDialogs } from 'redux/dialogs';
import i18n from 'i18n';
import API, { STATUS_SUCCESS } from 'utils/api';
import {
  createNotification,
  createNotificationByType,
  NOTIFICATION_TYPES,
} from 'utils/notification';
import { RESET_STATE } from './sharedActions';

const initialState = {
  isUpdatingPassword: false,
  isUpdatingPasswordByToken: false,
  passwordUpdateError: null,
  passwordUpdateByTokenError: null,
  isRequestingNewPassword: false,
  passwordRequestError: null,
  newPasswordSent: false,
  newPasswordSet: false,
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    forgotPasswordRequest(state) {
      state.isRequestingNewPassword = true;
      state.passwordRequestError = null;
      state.newPasswordSent = false;
    },
    forgotPasswordSuccess(state) {
      state.isRequestingNewPassword = false;
      state.newPasswordSent = true;
    },
    forgotPasswordFailure(state, action) {
      const { reason } = action.payload;
      state.isRequestingNewPassword = false;
      state.passwordRequestError = reason;
    },
    updatePasswordRequest(state) {
      state.isUpdatingPassword = true;
      state.passwordUpdateError = null;
    },
    updatePasswordSuccess(state) {
      state.isUpdatingPassword = false;
    },
    updatePasswordFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingPassword = false;
      state.passwordUpdateError = reason;
    },
    updatePasswordByTokenRequest(state) {
      state.isUpdatingPasswordByToken = true;
      state.passwordUpdateByTokenError = null;
      state.newPasswordSet = false;
    },
    updatePasswordByTokenSuccess(state) {
      state.isUpdatingPasswordByToken = false;
      state.newPasswordSet = true;
    },
    updatePasswordByTokenFailure(state, action) {
      const { reason } = action.payload;
      state.isUpdatingPasswordByToken = false;
      state.passwordUpdateByTokenError = reason;
      state.newPasswordSet = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export function updateUserPassword(passwordData) {
  return (dispatch, getState) => {
    dispatch(updatePasswordRequest());
    return API.post('/account/change-password', passwordData, {
      headers: {
        Authorization: `Bearer ${getState().authentication.token}`,
      },
    })
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(updatePasswordSuccess());
          dispatch(hideDialogs());
          dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_CHANGED));
        } else {
          dispatch(updatePasswordFailure(data));
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(updatePasswordFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    dispatch(forgotPasswordRequest());
    return API.post('/forgot-password', email)
      .then((response) => {
        const { data } = response;
        if (data.status === STATUS_SUCCESS) {
          dispatch(forgotPasswordSuccess());
          dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_FORGET_REQUESTED));
        } else {
          dispatch(forgotPasswordFailure(data));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(forgotPasswordFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export function setNewPasswordForToken(passwordData) {
  return (dispatch) => {
    dispatch(updatePasswordByTokenRequest());
    return API.post('/reset-password', passwordData)
      .then((response) => {
        const { data } = response;
        const { reason, status } = data;
        if (status === STATUS_SUCCESS) {
          dispatch(updatePasswordByTokenSuccess());
          dispatch(createNotificationByType(NOTIFICATION_TYPES.PASSWORD_CHANGED));
        } else {
          dispatch(updatePasswordByTokenFailure(data));
          dispatch(createNotification(reason, 'error'));
        }
      })
      .catch(() => {
        const networkError = i18n.t('network_error');
        dispatch(updatePasswordByTokenFailure({ reason: networkError }));
        dispatch(createNotificationByType(NOTIFICATION_TYPES.NETWORK_ERROR));
      });
  };
}

export const {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  updatePasswordByTokenRequest,
  updatePasswordByTokenSuccess,
  updatePasswordByTokenFailure,
} = passwordSlice.actions;
export default passwordSlice.reducer;
