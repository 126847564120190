import { createSlice } from '@reduxjs/toolkit';
import { RESET_STATE } from './sharedActions';

const initialState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification(state, action) {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload,
        },
      ];
    },
    closeNotifications(state, action) {
      const { dismissAll, key } = action.payload;
      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeNotification(state, action) {
      const { key } = action.payload;
      state.notifications = state.notifications.filter((notification) => notification.key !== key);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return { ...initialState };
    });
  },
});

export const { addNotification, closeNotifications, removeNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
