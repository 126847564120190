/* eslint-disable import/prefer-default-export */
import { BigNumber } from 'bignumber.js';

export const UNLIMITED_ALLOWANCE_IN_BASE_UNITS = new BigNumber(2).pow(256).minus(1);
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NULL_BYTES = '0x';
export const BIGINT_ZERO = new BigNumber(0);

export const SALE_MODE = {
  PRE_SALE_START: 0,
  PRE_SALE_END: 1,
  MAIN_SALE_START: 2,
  MAIN_SALE_END: 3,
};
