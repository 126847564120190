import { ERC20TokenContract } from '@0x/contract-wrappers';
import { BigNumber } from 'bignumber.js';

import { UNLIMITED_ALLOWANCE_IN_BASE_UNITS } from './constants';
import ZeroExWrapper from './ZeroExWrapper';

export async function checkAllowanceForTokenAmount(tokenAddress, owner, spender, amount) {
  const tokenContract = new ERC20TokenContract(
    tokenAddress,
    ZeroExWrapper.getInstance().getProvider()
  );
  let allowanceAmount;
  try {
    allowanceAmount = await tokenContract.allowance(owner, spender).callAsync();
  } catch (e) {
    console.error("Couldn't fetch token allowance!", e);
    allowanceAmount = new BigNumber(0);
  }
  return allowanceAmount.gte(amount);
}

export async function unlimitedAllowance(tokenAddress, owner, spender) {
  let tokenContractApprovalTxHash = null;
  try {
    const tokenContract = new ERC20TokenContract(
      tokenAddress,
      ZeroExWrapper.getInstance().getProvider()
    );
    tokenContractApprovalTxHash = await tokenContract
      .approve(spender, UNLIMITED_ALLOWANCE_IN_BASE_UNITS)
      .sendTransactionAsync({ from: owner });
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return tokenContractApprovalTxHash;
}

export async function transferERC20(tokenAddress, toAddress, amount) {
  let transactionHash = null;
  try {
    const tokenContract = new ERC20TokenContract(
      tokenAddress,
      ZeroExWrapper.getInstance().getProvider()
    );
    const [owner] = await ZeroExWrapper.getInstance().getWeb3Wrapper().getAvailableAddressesAsync();
    transactionHash = await tokenContract
      .transfer(toAddress, amount)
      .sendTransactionAsync({ from: owner });
    // eslint-disable-next-line no-empty
  } catch (error) {
    console.error(error);
    return null;
  }
  return transactionHash;
}

// TODO: add function
// eslint-disable-next-line no-unused-vars
export async function createSignedOrder(tokenAddress, makerWallet, bsptAmount, daiAmount) {
  return null;
}

export function sendContractTransaction(method, args) {
  return new Promise((resolve, reject) => {
    method
      .send(args)
      .on('transactionHash', (hash) => {
        resolve(hash);
      })
      .on('error', (error) => reject(error));
  });
}

export async function personalMessageSign(walletType, walletAddress, message) {
  let signedMessage = null;
  try {
    if (walletType === 'METAMASK') {
      const web3Wrapper = ZeroExWrapper.getInstance().getWeb3Wrapper();
      signedMessage = await web3Wrapper.sendRawPayloadAsync({
        method: 'personal_sign',
        params: [message, walletAddress],
      });
    } else if (walletType === 'TRUSTWALLET') {
      signedMessage = await ZeroExWrapper.getInstance()
        .getProvider()
        .send({
          method: 'personal_sign',
          params: [message, walletAddress],
        });
    }
  } catch (e) {
    console.error('Can not sign message', e);
  }
  return signedMessage;
}
