/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

const TYPE_METAMASK = 'METAMASK';
const TYPE_TRUSTWALLET = 'TRUSTWALLET';
const TYPE_FORTMATIC = 'FORTMATIC';

const initialState = {
  type: '',
  account: '',
  showedDialogAlready: false,
  handledWalletReconnect: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletType(state, action) {
      state.type = action.payload;
    },
    setAccount(state, action) {
      state.account = action.payload;
    },
    setShowedDialog(state) {
      state.showedDialogAlready = true;
    },
    setHandledWalletReconnect(state) {
      state.handledWalletReconnect = true;
    },
    resetWallet(state) {
      state.account = '';
      state.type = '';
      state.showedDialogAlready = false;
      state.handledWalletReconnect = false;
    },
  },
});

export { TYPE_METAMASK, TYPE_TRUSTWALLET, TYPE_FORTMATIC };

export const {
  setWalletType,
  setAccount,
  resetWallet,
  setShowedDialog,
  setReconnectWallet,
  setHandledWalletReconnect,
} = walletSlice.actions;
export default walletSlice.reducer;
