import ZeroExWrapper from 'utils/ZeroExWrapper';
import { ERC20Abi } from 'contracts';
import { getWeb3 } from './helper';

const getTokenBalance = async (contractAddress, userAccount) => {
  const library = getWeb3();
  const tokenContract = new library.eth.Contract(ERC20Abi, contractAddress);
  const tokenBalance = await tokenContract.methods.balanceOf(userAccount).call();
  return tokenBalance;
};

const getTokenAllowance = async (contractAddress, userAccount) => {
  const library = getWeb3();
  const contractWrappers = ZeroExWrapper.getInstance().getContractWrapper();
  const { erc20Proxy } = contractWrappers.contractAddresses;
  const tokenContract = new library.eth.Contract(ERC20Abi, contractAddress);
  const tokenAllowance = await tokenContract.methods.allowance(userAccount, erc20Proxy).call();
  return tokenAllowance;
};

export { getTokenBalance, getTokenAllowance };
